<template>
  <div class="AccountPortalView">
    <h1>{{ $t('account') }}</h1>
    {{ $t('redirect') }}
  </div>
</template>

<translations>
  account: Your membership
  account_no: Ditt medlemskap
  redirect: Please wait while we are redirecting you...
  redirect_no: Vennligst vent mens vi sender deg videre...
</translations>

<script>
export default {
    // components: { },
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {
        this.$store.dispatch('moduleAuth/getPortalUrl').then(portalUrl => {
            window.location.href = portalUrl;
        });
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.AccountPortalView {
    padding: 1em;
}
</style>
